<template>
  <div class=" container-layout-custom justify-center full-height ak-text light-theme-text-default" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row q-mb-xl justify-between items-end">
        <div class="text-h4">Announcements</div>
        <MediaSelect class="col-3" v-bind:selection.sync="selection" label="Showing Announcements for:" v-if="$store.getters['auth/homePageFilter']" />
      </div>
      <div class="row q-gutter-xl justify-between">
        <q-btn @click="showModal = true" color="primary">Add Announcement</q-btn>
      </div>

      <div class="row q-mt-xl">
        <div v-if="announcements.length > 0" class="col-xs-12">
          <q-list>
            <q-item v-for="announcement in announcements" :key="announcement.uid">
              <q-item-section class="col-2">
                <q-item-label>{{ announcement.publish_at.slice(0, 10) }}</q-item-label>
              </q-item-section>
              <q-item-section>
                <q-item-label>{{announcement.title}}</q-item-label>
              </q-item-section>
              <q-item-section class="col-1">
                <q-btn flat @click="selectForEdit(announcement)">Edit</q-btn>
              </q-item-section>
              <q-item-section class="col-1">
                <q-btn flat @click="removeAnnouncement(announcement)" :loading="(deleting === announcement.uid)">Delete</q-btn>
              </q-item-section>
            </q-item>
          </q-list>
          <div class="row full-width justify-center q-mt-lg">
            <q-pagination v-if="pageMax > 1" v-model="page" :max="pageMax" :max-pages="10" :direction-links="true" />
          </div>
        </div>
        <div v-else class="col-xs-12">
          <div align="center">There are currently no announcements for this Location or User Group.</div>
        </div>
      </div>

      <q-dialog v-model="showModal" @hide="mode = 'create'">
        <q-card style="width: 560px; max-height: 1000px;" class="bg-lmsBackground primaryText-text">
          <q-form @submit="mode === 'create' ? addAnnouncement() : saveAnnouncement()" @reset="showModal = false" autocomplete="off">
            <q-card-section>
              <div class="text-h5">{{ mode === 'create' ? 'Create New' : 'Edit' }} Announcement</div>
            </q-card-section>
            <q-card-section>
              <div class="col-xs-12 q-mb-lg">
                <label>
                  <q-input label="Title" type="text" v-model.trim="announcement.title" :rules="formRules.title"></q-input>
                </label>
              </div>
              <div class="col-xs-12 q-mb-lg">
                <q-input @click="$refs.qPublishDateProxy.show()" mask="##/##/####" fill-mask filled label="Publish Date" v-model="announcement.publish_at" :rules="formRules.date">
                  <template v-slot:prepend>
                    <q-icon name="event" class="cursor-pointer primaryText-text">
                      <q-popup-proxy ref="qPublishDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date today-btn v-model="announcement.publish_at"  @input="$refs.qPublishDateProxy.hide()" mask="MM/DD/YYYY" class="bg-lmsBackground text-primary"/>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
              <div class="col-xs-12 q-mb-lg" v-if="$store.getters['auth/homePageFilter']">
                <MediaSelect v-bind:selection.sync="announcement.assigned_uid" label="Assign To" class="full-width" />
              </div>
              <div class="col-xs-12 q-mb-lg">
                <q-input label="Announcement Body" type="text" v-model.trim="announcement.body" :rules="formRules.body"></q-input>
              </div>
            </q-card-section>
            <div class="row gutter-xs q-my-lg q-mx-lg">
              <div class="col-12 row justify-end">
                <q-btn label="Cancel" type="reset" flat />
                <q-btn :label="mode === 'create' ? 'Add' : 'Save'" type="submit" :loading="saving" color="primary" />
              </div>
            </div>
          </q-form>
        </q-card>
      </q-dialog>

    </div>
  </div>
</template>

<script>
import { date } from 'quasar'
import MediaSelect from 'components/MediaSelect'

export default {
  components: { MediaSelect },
  data () {
    return {
      mode: 'create',
      showModal: false,
      saving: false,
      deleting: null,
      announcement: {
        title: null,
        publish_at: date.formatDate(new Date(), 'MM/DD/YYYY'),
        assigned_uid: 'Company',
        body: null
      },
      formRules: {
        title: [ val => (val !== null && val !== '') || 'Please provide a title', val => val.length <= 20 || 'Please use maximum 20 characters' ],
        body: [ val => (val !== null && val !== '') || 'Please provide a body', val => val.length <= 200 || 'Please use maximum 200 characters' ],
        date: [ val => (val !== null && val !== '' && val !== undefined) || 'Please provide a date' ]
      },
      selection: null,
      page: 1,
      perPage: 10
    }
  },
  computed: {
    filteredAnnouncements () {
      return this.$store.getters['announcements/getByAssignedUid'](this.selection)
    },
    pageMax () {
      if (this.filteredAnnouncements.length === 0) return 0
      return Math.ceil(this.filteredAnnouncements.length / this.perPage)
    },
    announcements () {
      let startIndex = (this.page - 1) * this.perPage
      return this.filteredAnnouncements.slice(startIndex, startIndex + this.perPage)
    }
  },
  watch: {
    'announcement.publish_at' (val) {
      this.announcement.publish_at = date.formatDate(new Date(val), 'MM/DD/YYYY')
    },
    'showModal' (val) {
      if (!val) {
        this.announcement = {
          title: null,
          publish_at: date.formatDate(new Date(), 'MM/DD/YYYY hh:mm a'),
          assigned_uid: this.selection,
          body: null
        }
      }
    }
  },
  methods: {
    addAnnouncement () {
      this.saving = true
      this.$store.dispatch('announcements/add', this.announcement).then((response) => {
        this.saving = false
        this.showModal = false
        this.$successMsg()
      }).catch((e) => {
        this.saving = false
        this.$failureMsg()
      })
    },
    saveAnnouncement () {
      this.saving = true
      this.$store.dispatch('announcements/update', this.announcement).then((response) => {
        this.saving = false
        this.showModal = false
        this.$successMsg()
      }).catch((e) => {
        this.saving = false
        this.$failureMsg()
      })
    },
    selectForEdit ({ ...announcement }) {
      this.mode = 'edit'
      this.announcement = announcement
      this.showModal = true
    },
    removeAnnouncement (announcement) {
      this.deleting = announcement.uid
      this.$store.dispatch('announcements/remove', announcement.uid).then((response) => {
        this.$successMsg()
        this.deleting = null
      }).catch((e) => {
        this.$failureMsg()
        this.deleting = null
      })
    }
  },
  created () {
    this.$store.dispatch('announcements/fetch')
    this.selection = this.$isAllowed(['||', 'master-admin', 'site-admin'], true) ? 'Company' : this.$store.getters['auth/customer']
    this.announcement.assigned_uid = this.selection
  }
}
</script>
